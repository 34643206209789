import axiosClient from './axiosClient' 

const dashboardApi = {
  getOrderTotalPaidUnpaid: (params: any) => {
    const url = 'orderdashboard/gettotalpaidunpaid'
    return axiosClient.get(url, {params})
  },
  getOrderTable: (params: any) => {
    const url = 'orderdashboard/gettabledata'
    return axiosClient.get(url, {params})
  },
  getOrderChart: (params: any) => {
    const url = 'orderdashboard/getdashboardtotalincomeclient'
    return axiosClient.get(url, {params})
  },
  getOrderLineChart: (params: any) => {
    const url = 'orderdashboard/getlinechartgrowth'
    return axiosClient.get(url, {params})
  },

  getAuditTotalPaidUnpaid: (params: any) => {
    const url = 'auditdashboard/gettotalpaidunpaid'
    return axiosClient.get(url, {params})
  },
  getAuditTable: (params: any) => {
    const url = 'auditdashboard/gettabledata'
    return axiosClient.get(url, {params})
  },
  getAuditChart: (params: any) => {
    const url = 'auditdashboard/getdashboardtotalincomeclient'
    return axiosClient.get(url, {params})
  },
  getAuditLineChart: (params: any) => {
    const url = 'auditdashboard/getlinechartgrowth'
    return axiosClient.get(url, {params})
  },
  //////////OVERVIEW
  getTodayOverview: (params: any) => {
    const url = 'notification/getoverviewtoday'
    return axiosClient.get(url, {params})
  },
  getDayOverview: (params: any) => {
    const url = 'notification/getoverviewbyday'
    return axiosClient.get(url, {params})
  },
//   postFactory: (data: PayloadFactory) => {
//     const postData = {
//       name: data.name,
//       country: data.country,
//       contactPersons: [data.contactPerson],
//       email: data.email,
//       phoneNumbers: [data.phoneNumber],
//       address: data.address,
//       remark: data.remark,
//     }
//     const url = '/factory/createfactory'
//     return axiosClient.post(url, postData)
//   },
//   updateFactory: (data: PayloadFactory) => {
//     const updateData = {
//       name: data.name,
//       country: data.country,
//       contactPersons: [data.contactPerson],
//       email: data.email,
//       phoneNumbers: [data.phoneNumber],
//       address: data.address,
//       remark: data.remark,
//     }
//     const id = data.id
//     const url = `/factory/updatefactory/${id}`
//     return axiosClient.put(url, updateData)
//   },
//   deleteFactory: (id: string) => {
//     const url = `/factory/deletefactory?Id=${id}`
//     return axiosClient.delete(url)
//   },
}

export default dashboardApi
